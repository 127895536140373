// Load Mixins
// -------------------------------

@import "./node_modules/bootstrap/scss/mixins";

// // --> Mixin: Transform
// // -------------------------------

// @mixin transform($transform...) {
//     -moz-transform: $transform;
//          transform: $transform;
// }
//
// @mixin translateX($translateX) {
//     -moz-transform: translateX($translateX);
//          transform: translateX($translateX);
// }
//
// @mixin translateY($translateY) {
//     -moz-transform: translateY($translateY);
//          transform: translateY($translateY);
// }
//

@mixin translateXY($translateX, $translateY) {
    transform: translate($translateX, $translateY);
}
@mixin translate3d($translateX, $translateY, $translateZ) {
    transform: translate3d($translateX, $translateY, $translateZ);
}
