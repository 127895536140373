// Colors: Social Colors
// -------------------------------

$color-facebook   : #3b5999;
$color-twitter    : #55acee;
$color-google-plus: #df4a32;
$color-youtube    : #ff0000;
$color-instagram  : #c32aa3;
$color-linkedin   : #007bb5;
$color-whatsapp   : #4dc247;
$color-pinterest  : #cb2027;
$color-snapchat   : #fffc00;
$color-dropbox    : #007ee5;
$color-flickr     : #ff0084;
$color-tumblr     : #32506d;
$color-github     : #000000;
$color-html5      : #E44F26;
$color-css3       : #1572B6;
$color-yahoo      : #410093;
$color-behance    : #131418;
$color-skype      : #00AFF0;
$color-evernote   : #46bf8c;
$color-vimeo      : #1ab7ea;