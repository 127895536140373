// Site Variables: Page
// -------------------------------

$gray-optional:                         #60636D;

$page-bg:                               $white !default;
$page-color:                            $gray-optional;
$font-size-xs:                          ($font-size-base * .75) !default;
$font-weight-medium:                    500 !default;

// Site Variables: Section
// -------------------------------
$page-vertical-padding:                 50px;
$page-horizontal-padding:               50px;

// Site Variables: Header
// -------------------------------
$site-header-horizontal-padding:        25px;
$site-header-bg:                        $white;
$site-header-height:                    640px;
$site-menu-height:                      80px;
$site-menu-vertical-padding:            20px;

// Site Variables: Footer
// -------------------------------
$site-footer-vertical-padding:          50px;
$site-footer-bg:                        $gray-optional;
$site-footer-social:                    32px;